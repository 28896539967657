import React, { Component } from "react";

import { faCircleNotch } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export default class Loading extends Component {
  render() {
    return (
      <div className="loginLoader loginLoaderInner">
        <FontAwesomeIcon
          icon={faCircleNotch}
          spin={true}
          size="4x"
          color="#009EDB"
        />
        <h3>
          {this.props.message || this.props.message?.length === 0
            ? this.props.message
            : "Loading"}
        </h3>
      </div>
    );
  }
}
