import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";

export default class NotFound extends Component {
  getBackButton() {}

  render() {
    return (
      <div>
        <div className="loginLoader">
          <FontAwesomeIcon
            icon={faExclamationCircle}
            size="4x"
            color="#e0890f"
          />
          <h1>Not Found</h1>
          <p>
            This page doesn't seem to exist. Please contact Digadop support if
            you believe this is an error.
          </p>
          {this.getBackButton()}
        </div>
      </div>
    );
  }
}
