import {
  faChartBar,
  faChartLine,
  faList,
} from "@fortawesome/free-solid-svg-icons";
import Axios from "axios";
import CookieHelper from "./Helpers/Cookies";
// import log from "loglevel";
// import { toast } from "react-toastify";

export const queryParamsToString = (router) => {
  let params = "?";
  params += "source=" + router.query.source;
  params += "&ObjectType=" + router.query.ObjectType;
  params += "&RecordType=" + router.query.RecordType;
  params += "&ProfileId=" + router.query.ProfileId;
  params += "&Application=" + router.query.Application;
  params += "&OrganizationId=" + router.query.OrganizationId;
  params += "&UserId=" + router.query.UserId;
  if (router.query.IsSandbox === "true") {
    params += "&IsSandbox=true";
  }
  return params;
};

export const adjustForTimezone = (date) => {
  var timeOffsetInMS = date.getTimezoneOffset() * 60000;
  date.setTime(date.getTime() - timeOffsetInMS);
  return date;
};

export const sqlToJsDate = (sqlDate) => {
  //sqlDate in SQL DATETIME format ("yyyy-mm-dd hh:mm:ss.ms")
  var sqlDateArr1 = sqlDate.split("-");
  //format of sqlDateArr1[] = ['yyyy','mm','dd hh:mm:ms']
  var sYear = sqlDateArr1[0];
  var sMonth = (Number(sqlDateArr1[1]) - 1).toString();
  var sqlDateArr2 = sqlDateArr1[2].split(" ");
  //format of sqlDateArr2[] = ['dd', 'hh:mm:ss.ms']
  var sDay = sqlDateArr2[0];
  var sqlDateArr3 = sqlDateArr2[1].split(":");
  //format of sqlDateArr3[] = ['hh','mm','ss.ms']
  var sHour = sqlDateArr3[0];
  var sMinute = sqlDateArr3[1];
  var sqlDateArr4 = sqlDateArr3[2].split(".");
  //format of sqlDateArr4[] = ['ss','ms']
  var sSecond = sqlDateArr4[0];
  var sMillisecond = sqlDateArr4[1];

  return new Date(sYear, sMonth, sDay, sHour, sMinute, sSecond, sMillisecond);
};

export const getContentIconByType = (type) => {
  if (type === "Attachment") {
    return "s1icon s1icon-s-file";
  } else if (type === "Video") {
    return "s1icon s1icon-c-tvwidescreen";
  } else if (type === "Html") {
    return "s1icon s1icon-s-article";
  } else if (type === "URL") {
    return "s1icon s1icon-s-link";
  } else if (type === "SectionHeader") {
    return "s1icon s1icon-c-18";
  }
};

export const getBeautifulTypeByType = (type) => {
  if (type === "Html") {
    return "Article";
  } else if (type === "SectionHeader") {
    return "Seaction header";
  }
  return type;
};

export const formatDateFromSQLString = (date, includeTime = true) => {
  var now;
  if (typeof date === "string") {
    var t = date.split(/[- :]/);
    now = new Date(
      Date.UTC(
        t[0],
        t[1] - 1,
        t[2],
        t[3] ? t[3] : 0,
        t[4] ? t[4] : 0,
        t[5] ? t[5] : 0
      )
    );
  } else {
    now = date;
  }
  // var now = new Date(date + ' UTC')
  var d = "" + (now.getMonth() + 1);
  d = d + "/" + now.getDate();
  d = d + "/" + now.getFullYear();
  if (includeTime) {
    let hours = now.getHours();
    let time = "AM";
    if (hours > 11) {
      time = "PM";
    }
    if (hours > 12) {
      hours = hours - 12;
    }
    d = d + " " + padString(hours);
    d = d + ":" + padString(now.getMinutes()) + " " + time;
  }
  return d;
};

export const padString = (string) => {
  let str = "" + string;
  if (str.length === 1) {
    return "0" + str;
  }
  return str;
};

export const getIconFromReportType = (reportType) => {
  let icon = null;
  if (reportType === "TABLE") {
    icon = faList;
  } else if (reportType === "LINE_CHART") {
    icon = faChartLine;
  } else if (reportType === "BAR_CHART") {
    icon = faChartBar;
  }
  return icon;
};

export const getTimeStringFromSeconds = (date1, date2, time) => {
  if (typeof date1 === "string") {
    const t = date1.split(/[- :]/);
    date1 = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
  }
  if (typeof date2 === "string") {
    const t = date2.split(/[- :]/);
    date2 = new Date(Date.UTC(t[0], t[1] - 1, t[2], t[3], t[4], t[5]));
  }
  var secs = 0;
  if (time !== null) {
    secs = time;
  } else {
    secs = (date2.getTime() - date1.getTime()) / 1000;
  }
  if (secs > 60) {
    if (secs > 3600) {
      return Math.round((secs / 3600) * 10) / 10 + "h";
    }
    return Math.round((secs / 60) * 10) / 10 + "m";
  }
  if (secs === 0 || !secs) {
    return "-";
  }
  return secs + "s";
};

export const limitStringLength = (string, length) => {
  if (string && string.length > length) {
    return string.substr(0, length - 2) + "...";
  } else {
    return string;
  }
};

export const openInNewWindow = (url, winWidth = 300, winHeight = 500) => {
  let h = window.screenTop + window.screen.height / 3.5;
  let w = window.screenLeft + window.screen.width / 2;
  window.open(
    url,
    "Digadop Preview",
    "menubar=no,titlebar=no,toolbar=no,location=no,width=" +
    winWidth +
    ",height=" +
    winHeight +
    ",left=" +
    w +
    ",top=" +
    h
  );
};

export const extractContentFromHtml = (s) => {
  var span = document.createElement("span");
  span.innerHTML = s;
  return span.textContent || span.innerText;
};

//Takes Wizard Checked Options & Creates Display Settings for Display & for Server
export const generateViewSettingsFromWizardOptions = (
  checked,
  currentSettings,
  definitions
) => {
  let UI = checked.UI[0];
  let toReturn = [];
  // checked.Objects.forEach(obj => {
  //     checked.Profiles.forEach(pro => {
  //         checked.Applications.forEach(app => {
  //             let objLabel = definitions.Objects.filter(ob =>{ return ob.Id === obj })[0].Name;
  //             let proLabel = definitions.Profiles.filter(p =>{ return p.Id === pro })[0].Name;
  //             let appLabel = definitions.Applications.filter(a =>{ return a.Id === app })[0].Name;
  //             let setting = {
  //                 SalesforceSettingId: 0,
  //                 Object: {
  //                     Id: obj,
  //                     Name: objLabel
  //                 },
  //                 RecordType: {
  //                     Id: '*',
  //                     Name: '-'
  //                 },
  //                 Profile: {
  //                     Id: pro,
  //                     Name: proLabel
  //                 },
  //                 Application: {
  //                     Id: app,
  //                     Name: appLabel
  //                 }
  //             }
  //             let add = true;
  //             currentSettings.forEach(set => {
  //                 if(set.Object){
  //                     if(set.Object.Id === setting.Object.Id) {
  //                         if(set.RecordType){
  //                             if(set.RecordType.Id === setting.RecordType.Id) {
  //                                 if(set.Profile.Id === setting.Profile.Id) {
  //                                     if(set.Application.Id === setting.Application.Id) {
  //                                         add = false
  //                                     }
  //                                 }
  //                             }
  //                         }else{
  //                             if(set.Profile.Id === setting.Profile.Id) {
  //                                 if(set.Application.Id === setting.Application.Id) {
  //                                     add = false
  //                                 }
  //                             }
  //                         }
  //                     }
  //                 }
  //             })
  //             if(add){
  //                 log.debug('Added', setting);
  //                 currentSettings.push(setting);
  //             }else{
  //                 log.debug('Already Exists', setting);
  //             }
  //         })
  //     })
  // })
  checked.RecordTypes.forEach((rec) => {
    checked.Profiles.forEach((pro) => {
      checked.Applications.forEach((app) => {
        let obj;
        obj = definitions.Objects.filter((ob) => {
          return ob.Id === rec.ObjectType;
        })[0];
        let objLabel = obj.Name;
        // let recLabel = rec.Name;
        let proLabel = definitions.Profiles.filter((p) => {
          return p.Id === pro;
        })[0].Name;
        let appLabel = definitions.Applications.filter((a) => {
          return a.Id === app;
        })[0].Name;
        let setting = {
          SalesforceSettingId: 0,
          UI: {
            Id: UI,
            Name: UI,
          },
          Object: {
            Id: obj.Id,
            Name: objLabel,
          },
          RecordType: {
            Id: rec.Id,
            Name: rec.Name,
          },
          Profile: {
            Id: pro,
            Name: proLabel,
          },
          Application: {
            Id: app,
            Name: appLabel,
          },
        };
        let add = true;
        currentSettings.forEach((set) => {
          // log.debug(set, setting);
          if (set.Object && set.RecordType) {
            if (
              set.Object.Id === setting.Object.Id &&
              set.RecordType.Id === setting.RecordType.Id
            ) {
              if (set.Profile.Id === setting.Profile.Id) {
                if (set.UI.Id === setting.UI.Id) {
                  add = false;
                }
              }
            }
          }
        });
        if (add) {
          // log.debug("Did Add", setting);
          toReturn.push(setting);
        } else {
          // log.debug("Already exists");
        }
      });
    });
  });
  checked.Pages.forEach((page) => {
    checked.Profiles.forEach((pro) => {
      checked.Applications.forEach((app) => {
        let pageO = definitions.Pages.filter((p) => {
          return p.Id === page;
        })[0];
        let pageLabel = pageO.Name;
        let proLabel = definitions.Profiles.filter((p) => {
          return p.Id === pro;
        })[0].Name;
        let appLabel = definitions.Applications.filter((a) => {
          return a.Id === app;
        })[0].Name;
        let setting = {
          SalesforceSettingId: 0,
          UI: {
            Id: UI,
            Name: UI,
          },
          Page: {
            Id: pageO.Id,
            Name: pageLabel,
          },
          Profile: {
            Id: pro,
            Name: proLabel,
          },
          Application: {
            Id: app,
            Name: appLabel,
          },
        };
        let add = true;
        currentSettings.forEach((set) => {
          if (set.Page) {
            if (set.Page.Id === setting.Page.Id) {
              if (set.Profile.Id === setting.Profile.Id) {
                if (set.Application.Id === setting.Application.Id) {
                  if (set.UI.Id === setting.UI.Id) {
                    add = false;
                  }
                }
              }
            }
          }
        });
        if (add) {
          // log.debug("Did Add", setting);
          toReturn.push(setting);
        } else {
          // log.debug("Already exists", setting);
        }
      });
    });
  });
  //Returns Previous + new Settings
  return toReturn;
};

export const getSalesforceMetaData = (userData, refresh = 0) =>
  new Promise((res, rej) => {
    // log.debug(
    //   "Calling for Salesforce Metadata, LocalStorage expired or non-existent"
    // );
    Axios.get("https://" + process.env.REACT_APP_API + "/metadata/salesforce", {
      headers: { serverVersion: process.env.REACT_APP_VERSION, jwttoken: userData.token, refresh },
    })
      .then((success) => {
        // log.debug("Got SF Metadata:", success);
        success.data.Objects.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        success.data.Pages.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        success.data.RecordTypes.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        // Add Option for "Master RecordType. Once per unique Object"
        let usedObjs = [];
        success.data.RecordTypes.forEach((rec) => {
          if (!usedObjs.includes(rec.ObjectType)) {
            usedObjs.push(rec.ObjectType);
          }
        });
        let newRecTypes = [];
        success.data.Objects.forEach((o) => {
          newRecTypes.push({
            Id: "Tab_View",
            Name: "Tab View",
            ObjectType: o.Id,
          });
          newRecTypes.push({
            Id: "List_View",
            Name: "List View",
            ObjectType: o.Id,
          });
          newRecTypes.push({
            Id: "Search_View",
            Name: "Search View",
            ObjectType: o.Id,
          });
          newRecTypes.push({
            Id: "*",
            Name: "Master",
            ObjectType: o.Id,
          });
        });
        success.data.RecordTypes = [
          ...newRecTypes,
          ...success.data.RecordTypes,
        ];
        success.data.Profiles.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        success.data.Profiles = [
          { Id: "*", Name: "ALL" },
          ...success.data.Profiles,
        ];
        success.data.Applications.sort((a, b) => (a.Name > b.Name ? 1 : -1));
        success.data.Applications = [
          { Id: "*", Name: "ALL" },
          ...success.data.Applications,
        ];
        res(success.data);
      })
      .catch((error) => {
        // log.error(error);
        rej(error);
      });
  });

// Receives SF Metadata Definitions & Current Layout Settings
// Returns array of nice view settings with {Id: '', Name: ''} for all options
export const matchDefinitionsWithViewSettings = (definitions, viewSettings) => {
  let settings = [];
  // log.debug("Matching Definitions to View Settings", definitions, viewSettings);
  return new Promise((res, rej) => {
    try {
      viewSettings.forEach((set) => {
        let setting = {};
        // log.debug("Setting: ", set);
        setting.UI = { Id: set.UserInterfaceType, Name: set.UserInterfaceType };
        setting.SalesforceSettingId = set.SalesforceSettingId;
        if (set.Object !== "*") {
          let objs = definitions.Objects.filter((ob) => {
            return ob.Id === set.Object;
          });
          if (objs.length > 0) {
            setting.Object = {
              Id: set.Object ? set.Object : "*",
              Name: objs[0].Name,
            };
          } else {
            setting.Object = {
              Id: set.Object ? set.Object : "*",
              Name: set.Object + " *",
              NotFound: true,
            };
          }
        }
        if (set.RecordType && setting.Object) {
          let recs = definitions.RecordTypes.filter((ob) => {
            return ob.Id === set.RecordType;
          });
          if (recs.length > 0) {
            setting.RecordType = {
              Id: set.RecordType ? set.RecordType : "*",
              Name: recs[0].Name,
            };
          } else {
            let matchingObjs = definitions.RecordTypes.filter((rec) => {
              return rec.ObjectType === set.Object;
            });
            setting.RecordType = {
              Id: set.RecordType ? set.RecordType : "*",
              Name:
                set.RecordType === "*"
                  ? matchingObjs.length === 0
                    ? "-"
                    : "Master"
                  : set.RecordType + " *",
              NotFound: set.RecordType === "*" ? false : true,
            };
          }
        }
        if (set.PageName !== "*") {
          let pages = definitions.Pages.filter((ob) => {
            return ob.Name === set.PageName;
          });
          if (pages.length > 0) {
            setting.Page = {
              Id: set.PageName ? set.PageName : "*",
              Name: pages[0].Name,
            };
          } else {
            setting.Page = {
              Id: set.PageName ? set.PageName : "*",
              Name: set.PageName + " *",
              NotFound: true,
            };
          }
        }
        let profiles = definitions.Profiles.filter((ob) => {
          return ob.Id === set.Profile;
        });
        if (profiles.length > 0) {
          setting.Profile = {
            Id: set.Profile ? set.Profile : "*",
            Name: set.Profile ? profiles[0].Name : "-",
          };
        } else {
          setting.Profile = {
            Id: set.Profile ? set.Profile : "*",
            Name: set.Profile === "*" ? "ANY" : set.Profile + " *",
            NotFound: set.Profile === "*" ? false : true,
          };
        }
        let apps = definitions.Applications.filter((ob) => {
          return ob.Id === set.Application;
        });
        if (apps.length > 0) {
          setting.Application = {
            Id: set.Application ? set.Application : "*",
            Name: set.Application ? apps[0].Name : "-",
          };
        } else {
          setting.Application = {
            Id: set.Application ? set.Application : "*",
            Name: set.Application === "*" ? "ANY" : set.Application + " *",
            NotFound: set.Application === "*" ? false : true,
          };
        }
        if (
          setting.Application &&
          setting.Profile &&
          (setting.Object || setting.Page)
        ) {
          settings.push(setting);
        }
      });
      // log.debug("Returning Settings: ", settings);
      res(settings);
    } catch (error) {
      // log.error("Error matching definitions & Salesforce settings", error);
      rej(error);
    }
  });
};

export const getDecodedParamsAsObject = (route) => {
  const params = {};
  let searchParams = new URLSearchParams(route);
  for (const [key, val] of searchParams.entries()) {
    params[key] = decodeURIComponent(val.replace("+", "%20"));
  }
  return params;
};

export const signOut = () => {
  let curUser = CookieHelper.getCookie("cur_user");
  // CookieHelper.deleteCookie('cur_user');
  CookieHelper.deleteCookie(curUser, { path: "/" });
  let cur_accounts = CookieHelper.getCookie("cur_accounts");
  if (cur_accounts && cur_accounts.length > 0) {
    cur_accounts = JSON.parse(cur_accounts);
    cur_accounts = cur_accounts.filter((string) => string !== curUser);
    if (cur_accounts.length > 0) {
      let valid = [];
      cur_accounts.forEach((acc) => {
        let cook = CookieHelper.getCookie("cur_accounts");
        if (cook) {
          valid.push(acc);
        }
      });
      CookieHelper.setCookie("cur_accounts", JSON.stringify(valid));
    } else {
      CookieHelper.deleteCookie("cur_accounts");
      CookieHelper.deleteCookie("cur_user");
    }
  } else {
    CookieHelper.deleteCookie("cur_accounts");
    CookieHelper.deleteCookie("cur_user");
  }
  setTimeout(() => {
    window.location.reload();
  }, 500);
};
