import log from "loglevel";
import React, { Component } from "react";
import { CookiesProvider } from "react-cookie";
import { Provider } from "react-redux";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import configureStore from "./Redux/store";
import Routes from "./Routes";
import "./Styles.css";

import pkg from "../package.json";

console.log('1');
console.log('REACT_APP_VERSION' ,process.env.REACT_APP_VERSION);
console.log('REACT_APP_API',process.env.REACT_APP_API);
try {
log.setDefaultLevel(
  process.env.REACT_APP_API.includes("dev") ? "TRACE" : process.env.REACT_APP_API.includes("qa") ? "TRACE" : "WARN"
  );
}
catch(err) {};

  console.log('2');

const store = configureStore();

console.info(
  `Digadop App v${pkg.version}/${process.env.REACT_APP_VERSION}.${process.env.REACT_APP_API}`
);

class App extends Component {
  render() {
    return (
      <Provider store={store}>
        <ToastContainer
          position="bottom-center"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />
        <CookiesProvider>
          <Routes />
        </CookiesProvider>
      </Provider>
    );
  }
}
export default App;
