export default class CookieHelper {
  static getCookie(name) {
    name = name + "=";
    var decodedCookie = decodeURIComponent(document.cookie);
    var ca = decodedCookie.split(";");
    for (var i = 0; i < ca.length; i++) {
      var c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return false;
  }

  static setCookie(name, value) {
    //Set expiration to 7 days
    let now = new Date();
    now.setDate(now.getDate() + 7);
    document.cookie =
      name +
      "=" +
      encodeURIComponent(value) +
      ";expires=" +
      now.toUTCString() +
      ";path=/;" +
      (process.env.REACT_APP_API.includes("localhost")
        ? ""
        : "samesite=none;secure=true");
  }

  static deleteCookie(name) {
    let now = new Date();
    document.cookie = name + "=;path=/;expires=" + now;
  }
}
