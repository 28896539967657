import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Component } from "react";
import { Link } from "react-router-dom";
import { signOut } from "../../../Functions";
import { Offline, Online } from "react-detect-offline";
// import log from "loglevel";

export default class Error extends Component {
  constructor(props) {
    super(props);
    this.state = {
      inactiveTenant: false,
      title: props.title ? props.title : "Error Loading",
      description: "We ran into an error loading this page. Please try again.",
      onClick: this.props.tryAgain,
      redirectTo: false,
      buttonText: "Try Again",
    };
  }

  componentDidMount() {
    // log.warn("Error Received by Error Component: ", this.props.error);
    if (this.props.error) {
      if (this.props.error.status === 401 || this.props.error.status === 403) {
        if (this.props.error.data.message === "Unauthorized Tenant") {
          this.setState({
            title: "Company Account Suspended",
            description: "You don't have any valid Digadop contracts.",
            onClick: false,
            redirectTo: "/admin/support",
            buttonText: "Contact Us",
          });
        } else if (this.props.error.data.message === "Disabled User") {
          this.setState({
            title: "Account Disabled",
            description:
              "Please contact your system administrator if you believe this is an error.",
            onClick: false,
            redirectTo: false,
            buttonText: "Contact Us",
          });
        } else if (this.props.error.data.message === "Unauthorized User") {
          this.setState({
            title: "Authentication Error",
            description: "Please try logging in again.",
            onClick: signOut,
            redirectTo: false,
            buttonText: "Sign Out",
          });
        } else {
          this.setState({
            title: "Access Denied",
            description:
              "It doesn't look like you have permission to access this item.",
          });
        }
      } else if (this.props.error.status === 404) {
        this.setState({
          title: "Not Found",
          description: "We could not find what you were looking for.",
        });
      }
    }
  }

  render() {
    return (
      <div>
        <Online>
          <div style={{ minHeight: "50px" }}>
            <div className="loginLoader">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="4x"
                color="#a83136"
              />
              <h1>{this.state.title}</h1>
              <p>{this.state.description}</p>
              {this.state.onClick ? (
                <button
                  className="btn btn-primary"
                  onClick={this.state.onClick.bind(this)}
                >
                  {this.state.buttonText}
                </button>
              ) : null}
              {this.state.redirectTo ? (
                <Link className="btn btn-primary" to={this.state.redirectTo}>
                  {this.state.buttonText}
                </Link>
              ) : null}
            </div>
          </div>
        </Online>
        <Offline>
          <div style={{ minHeight: "50px" }}>
            <div className="loginLoader">
              <FontAwesomeIcon
                icon={faExclamationCircle}
                size="4x"
                color="#a83136"
              />
              <h1>Connection Offline</h1>
              <p>
                Digadop needs to be able to connect to our servers. Please fix
                your connection and try again.
              </p>
              {this.state.onClick ? (
                <button
                  className="btn btn-primary"
                  onClick={this.state.onClick.bind(this)}
                >
                  {this.state.buttonText}
                </button>
              ) : null}
              {this.state.redirectTo ? (
                <Link className="btn btn-primary" to={this.state.redirectTo}>
                  {this.state.buttonText}
                </Link>
              ) : null}
            </div>
          </div>
        </Offline>
      </div>
    );
  }
}
