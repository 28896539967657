import { FETCH_CONTENT } from "../actions/actionTypes";

const initialState = {
  status: "loading",
};

export const contentReducer = (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CONTENT:
      return { ...state, helpContents: action.payload, status: action.status };
    default:
      return state;
  }
};
