import { instanceOf, PropTypes } from "prop-types";
import React, { Component } from "react";
import { Cookies, withCookies } from "react-cookie";
import { connect } from "react-redux";
import { Redirect } from "react-router";
//import OAuth from './OAuth.js'
import io from "socket.io-client";
import { saveUser } from "../../Redux/actions/userActions";
import { toast } from "react-toastify";
import CookieHelper from "../../Helpers/Cookies";
import logo from "../../assets/images/logo.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSalesforce, faGoogle } from "@fortawesome/free-brands-svg-icons";
import {
  faCheckCircle,
  faCircleNotch,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import { limitStringLength } from "../../Functions";
import profileImg from "./../../assets/images/defaultProfileImg.png";
import Axios from "axios";
// import log from "loglevel";

class LoginScreen extends Component {
  handlingLogin = false;

  socket;

  constructor(props) {
    super(props);
    this.state = {
      loggedin: false,
      errorOrgWideLogin: 0,
      socketConnected: false,
    };
  }

  componentDidMount() {
    if (this.props.router.query.toToken) {
      this.processOrgWideLogin();
    } else {
      this.socket = io("https://" + process.env.REACT_APP_API);
      this.socket.on("connect", () => {
        this.setState({ socketConnected: true });
        // log.debug("Socket Connected");
      });
    }
  }

  processOrgWideLogin() {
    this.setState({ errorOrgWideLogin: false });
    Axios.get("https://" + process.env.REACT_APP_API + "/AuthenticateToken/", {
      headers: { usertoken: this.props.router.query.toToken },
    })
      .then((success) => {
        // log.debug(success);
        let id = success.data.orgId + "." + success.data.userId;
        CookieHelper.setCookie(id, JSON.stringify(success.data));
        CookieHelper.setCookie("cur_user", id);
        let cur_accounts = CookieHelper.getCookie("cur_accounts");
        if (cur_accounts) {
          cur_accounts = JSON.parse(cur_accounts);
          if (cur_accounts.indexOf(id) === -1) {
            cur_accounts.push(id);
            CookieHelper.setCookie(
              "cur_accounts",
              JSON.stringify(cur_accounts)
            );
          }
        } else {
          cur_accounts = [];
          cur_accounts.push(id);
          CookieHelper.setCookie("cur_accounts", JSON.stringify(cur_accounts));
        }
        this.props.saveUser(success.data);
        this.setState({ loggedin: true, errorOrgWideLogin: false });
      })
      .catch((error) => {
        this.setState({ errorOrgWideLogin: true });
        // log.error(error);
      });
  }

  openPopup() {
    // const {provider, socket} = this.props;
    const width = 600,
      height = 600;
    let top = window.screenTop + window.screen.height / 3.5;
    let left = window.screenLeft + window.screen.width / 2;
    // const w = 600;
    // const h = 600;
    // const y = window.top.outerHeight / 2 + window.top.screenY - ( h / 2);
    // const x = window.top.outerWidth / 2 + window.top.screenX - ( w / 2);
    let url = `https://${process.env.REACT_APP_API}/auth/salesforce/?socketId=${this.socket.id}`;
    // log.debug(this.props.router);
    if (this.props.router.query.IsSandbox === "true") {
      url = `https://${process.env.REACT_APP_API}/auth/salesforceSB/?socketId=${this.socket.id}`;
    }
    // log.debug("opening: " + url + " location " + top + left + width + height);
    return window.open(
      url,
      "",
      `toolbar=no location=no directories=no status=no menubar=no
                                    scrollbars=no resizable=no copyhistory=no width=${width}
                                    height=${height} top=${top} left=${left}`
    );
  }

  startAuth(e) {
    //        e.preventDefault();
    this.popup = this.openPopup();
    //window.open().close();

    this.checkPopup();
  }

  checkPopup() {
    const check = setInterval(() => {
      const { popup } = this;
      if (!popup || popup.closed || popup.closed === undefined) {
        clearInterval(check);
      }
    }, 1000);
  }

  showPopup() {
    // log.debug("in ShowPopup");
    //  const {socket, provider } = this.props;
    // log.debug("Opening Socket salesforce" + this.socket.id);
    this.socket.on("salesforce", (user) => {
      this.socketonCallback("salesforce", user);
    });
    this.startAuth(this);
  }

  socketonCallback(provider, user) {
    if (this.handlingLogin) {
      return;
    }
    this.handlingLogin = true;
    const { popup } = this;
    // log.debug(
    //   "in socket.on cb" +
    //     JSON.stringify(provider) +
    //     " - " +
    //     JSON.stringify(user)
    // );
    this.popup.close();
    // log.debug("got user" + JSON.stringify(user));
    if (
      this.props.router.query.OrganizationId &&
      this.props.router.query.UserId
    ) {
      if (
        this.props.router.query.OrganizationId !== user.orgId ||
        this.props.router.query.UserId !== user.userId
      ) {
        toast.error("Users do not match");
        return;
      }
    }
    this.props.saveUser(user);
    let orgID = user.orgId;
    let userID = user.userId;
    // this.props.cookies.set(orgID, user, { path: '/', maxAge: 60, sameSite: 'none', secure: true});
    // this.props.cookies.set('cur_org', orgID, { path: '/', maxAge: 60, sameSite: 'none', secure: true});
    //document.cookie = 'cur_org=' + orgID + ';path=/;samesite=none;secure=true'
    CookieHelper.setCookie(orgID + "." + userID, JSON.stringify(user));
    CookieHelper.setCookie("cur_user", orgID + "." + userID);
    // let cookie = CookieHelper.getCookie(orgID + "." + userID);
    // log.debug(cookie);
    let cur_accounts = CookieHelper.getCookie("cur_accounts");
    if (cur_accounts) {
      cur_accounts = JSON.parse(cur_accounts);
      if (cur_accounts.indexOf(orgID + "." + userID) === -1) {
        cur_accounts.push(orgID + "." + userID);
        CookieHelper.setCookie("cur_accounts", JSON.stringify(cur_accounts));
      }
    } else {
      cur_accounts = [];
      cur_accounts.push(orgID + "." + userID);
      CookieHelper.setCookie("cur_accounts", JSON.stringify(cur_accounts));
    }
    popup.close();
    this.socket.removeAllListeners();
    this.socket.close();
    toast.success("Login Successful");
    this.setState({ loggedin: true });
    this.handlingLogin = false;
  }

  swapAccount(id) {
    let newUser = JSON.parse(CookieHelper.getCookie(id));
    CookieHelper.setCookie("cur_user", id);
    this.props.saveUser(newUser);
    this.setState({ loggedin: true });
    toast.success("Logged in as " + newUser.name);
  }

  getCurAccounts() {
    let accounts = CookieHelper.getCookie("cur_accounts")
      ? JSON.parse(CookieHelper.getCookie("cur_accounts"))
      : null;
    let toReturn = [];
    if (accounts) {
      if (accounts.length > 0) {
        let usedAccounts = [];
        accounts.forEach((id) => {
          if (usedAccounts.includes(id)) {
            return;
          }
          let acc = JSON.parse(CookieHelper.getCookie(id));
          if (acc) {
            usedAccounts.push(id);
            let matched =
              this.props.user.userData.orgId +
                "." +
                this.props.user.userData.userId ===
              id;
            // let style = {
            //   color: matched ? "darkgray" : "#007bff",
            //   cursor: matched ? "not-allowed" : "pointer",
            // };
            toReturn.push(
              <a
                href="#0"
                className="card"
                onClick={this.swapAccount.bind(this, id)}
                key={id}
              >
                <div className="card-detail p-0">
                  <div
                    style={{
                      display: "grid",
                      gridTemplateColumns: "15% 70% 15%",
                    }}
                  >
                    <img
                      src={acc.picture ? acc.picture : profileImg}
                      alt=""
                      style={{
                        width: "25px",
                        height: "25px",
                        borderRadius: "15px",
                        gridColumn: "1",
                      }}
                      className="pull-left m-3"
                      ref={(img) => (this[id] = img)}
                      onError={() => {
                        this.replaceImage(id);
                      }}
                    />
                    <span
                      className="pt-4"
                      style={{
                        gridColumn: "2",
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                      }}
                    >
                      {limitStringLength(acc.name, 29)}{" "}
                    </span>
                    <span
                      className="p-4"
                      style={{ gridColumn: "3", paddingRight: "40px" }}
                    >
                      {matched ? (
                        <FontAwesomeIcon
                          icon={faCheckCircle}
                          className="text-success"
                          size="1x"
                        />
                      ) : null}
                    </span>
                  </div>
                </div>
              </a>
            );
          }
        });
        if (usedAccounts.length > 0) {
          return (
            <div className="card-list">
              <div className="card-list-heading">
                Currently Logged into {usedAccounts.length} Accounts
              </div>
              <div style={{ maxHeight: "150px", overflowY: "auto" }}>
                {toReturn}
              </div>
            </div>
          );
        }
      } else {
        return null;
      }
    }
    return null;
  }

  replaceImage(id) {
    // log.debug("replacing");
    this[id].src = profileImg;
  }

  render() {
    if (this.state.loggedin) {
      if (this.props.router.state?.prevRouter) {
        return (
          <Redirect
            to={{
              pathname: this.props.router.state.prevRouter.pathname,
              search: this.props.router.state.prevRouter.search,
            }}
          />
        );
      } else {
        if (this.props.router.query.source) {
          return (
            <Redirect
              to={{ pathname: "/learn", search: this.props.router.search }}
            />
          );
        } else {
          return <Redirect to={{ pathname: "/admin" }} />;
        }
      }
    } else {
      if (this.props.router.query.source === "salesforce") {
        if (this.props.router.query.toToken) {
          if (this.state.errorOrgWideLogin) {
            return (
              <div className="loginLoader">
                <FontAwesomeIcon
                  icon={faExclamationCircle}
                  size="4x"
                  className="text-danger"
                />
                <h1>Error Logging In</h1>
                <button
                  className="btn btn-primary"
                  onClick={this.processOrgWideLogin.bind(this)}
                >
                  Try Again
                </button>
                <p className="text-muted">
                  Still have issues? Try reloading your webpage.
                </p>
              </div>
            );
          } else {
            return (
              <div className="loginLoader">
                <FontAwesomeIcon
                  icon={faCircleNotch}
                  spin={true}
                  size="4x"
                  color="#009EDB"
                />
                <h1>Logging you in....</h1>
              </div>
            );
          }
        } else {
          return (
            <div className="loginLoader">
              {/* <FontAwesomeIcon icon={faCircleNotch} spin={true} size="4x" color="#009EDB" /> */}
              <h1>Login to Get Help</h1>
              <button
                onClick={this.showPopup.bind(this)}
                className={
                  "btn btn-primary btn-sm " +
                  (this.state.socketConnected ? "" : "disabled")
                }
              >
                Open Login Window
              </button>
            </div>
          );
        }
      } else {
        return (
          <div className="loginLoader">
            <div
              className="card p-5 mt-5"
              style={{ backgroundColor: "rgb(46,65,83)" }}
            >
              <div className="card-detail">
                <img src={logo} alt="logo" style={{ height: "30px" }} />
                <h1 style={{ color: "white" }} className="mb-5">
                  Welcome to Digadop
                </h1>
                <form>
                  <input
                    type="email"
                    className="form-control mb-2"
                    placeholder="E-mail"
                    disabled
                  ></input>
                  <input
                    type="password"
                    className="form-control mb-2"
                    placeholder="Password"
                    disabled
                  ></input>
                  <input
                    type="submit"
                    className="btn btn-primary btn-block mb-5"
                    value="Login"
                    disabled
                  ></input>
                </form>
                <p className="text-muted pb-0 mb-0">OR</p>
                <hr className="mt-3" />
                <button
                  className={
                    this.state.socketConnected
                      ? "btn btn-primary btn-block"
                      : "btn btn-primary btn-block disabled"
                  }
                  onClick={this.showPopup.bind(this)}
                >
                  <FontAwesomeIcon icon={faSalesforce} size="lg" /> Login with
                  Salesforce
                </button>
                <button
                  className={
                    this.state.socketConnected
                      ? "btn btn-default btn-block disabled"
                      : "btn btn-primary btn-block disabled"
                  }
                  onClick={this.showPopup.bind(this)}
                >
                  <FontAwesomeIcon icon={faGoogle} size="1x" /> Login with
                  Google
                </button>
                {/* { this.state.socketConnected ? null : <p className='text-muted' >Connecting to Servers...</p>} */}
              </div>
            </div>
            {this.getCurAccounts()}
          </div>
        );
      }
    }
  }
}

LoginScreen.propTypes = {
  saveUser: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
  cookies: instanceOf(Cookies).isRequired,
};

const mapStateToProps = (state) => ({
  user: state.user,
  router: state.router.location,
});

export default withCookies(connect(mapStateToProps, { saveUser })(LoginScreen));
